import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasClassicTheme } from 'common/src/theme/saasClassic';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/SaasClassic/saasClassic.style';
import '@redq/reuse-modal/es/index.css';

// COMPONENTS
import SEO from '../../components/seo';
import UserList from 'common/src/components/UserList';

// CONTAINERS
// import BannerSection from '../containers/SaasClassic/Banner';
import Navbar from '../../containers/SaasClassic/Navbar';
// import Newsletter from '../containers/SaasClassic/Newsletter';
// import Footer from '../containers/SaasClassic/Footer';

// HANDLERS
// import { redirectToPage } from '../../../../api/BrowserAPI'

const UsersPage = () => {
  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <SEO title="podstacks | ADMIN - Users" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <UserList
            title="User List"
            // tileClickCB={ podcast =>
            //   redirectToPage( 'admin/searchEpisodes', { jobId: podcast.id })
            // }
            // bgCol={{bg: "#cae8d5"}}
          />

          {/* <Footer /> */}
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default UsersPage;
